//Fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*
BEGIN BOOTSTRAP
----------------------------------------------
*/
//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/mixins";

$light-gray: #979797;
$dark: #444444;
$primary: #424242;
$secondary: #00c9d0;
$success: #88be68;
$info: #67c1c4;
$warning: #c4a667;
$danger: #e95c5c;
$light: #d8d8d8;

$font-family-sans-serif: "Poppins", sans-serif;

//$test: theme-color-level(danger, +10);

//Text & Fonts
$body-color: $light-gray;
$headings-color: $dark;

//Links
$link-color: $primary;
$link-hover-color: $secondary;
$link-hover-decoration: none;

//Breadcrumbs
$breadcrumb-bg: none;

// Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap";

/* ---------------------------------------------- */

/*
BEGIN React Redux Toaster
----------------------------------------------
*/
@import "~react-redux-toastr/src/styles/variables.scss";
$successBgColor: $success;
$infoBgColor: $info;
$warningBgColor: $warning;
$errorBgColor: $danger;
@import "~react-redux-toastr/src/styles/index";

/* ---------------------------------------------- */

html,
body {
  height: 100%;
  letter-spacing: -0.04rem;
  width: auto !important;
  overflow-x: hidden !important;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Prompt", sans-serif !important;
}

h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: -0.08rem;
}

h1,
.h1 {
  letter-spacing: -0.1rem;
}

.text-strong {
  letter-spacing: 0rem;
}

.badge {
  letter-spacing: 0.04rem;
}

.full-height {
  height: 100% !important;
}

.hide {
  display: none;
}

.sticky {
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
}

.nav-spacer {
  padding-top: 8rem;
}

.nav-spacer-breadcrumb {
  padding-top: 5.5rem;
}

.form-group select {
  height: 3.125rem;
}

/* Custom text classes
-------------------------------------------------- */
.text-strong {
  font-weight: bold !important;
}

.text-italics {
  font-style: italic !important;
}

.text-strike {
  text-decoration: line-through;
}

/* Nav Bar
-------------------------------------------------- */
.navbar {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.navbar-toggler {
  color: $dark !important;
}
.navbar-toggler i {
  padding: 5px 7px 5px 7px;
}
.nav-link {
  letter-spacing: 0.01rem;
  font-weight: 500;
}
.navbar-brand {
  height: 50px;
  width: 84px;
  background-image: url("https://res.cloudinary.com/hxcovqiub/image/upload/v1613150732/Logos/eye-icon_small-black_oslray.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 84px 50px;
}

.nav-cart-icon,
.navbar .navbar-nav .nav-item .nav-link {
  text-transform: uppercase;
  color: $dark;
}
.nav-cart-icon:hover,
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: #000;
}

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 80px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #ffffff;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    font-size: 1.5rem;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
  .navbar .navbar-nav .nav-item .nav-link {
    border-top: 1px solid #ebebeb;
    padding-left: 0.5rem;
  }
  .nav-cart-icon {
    font-size: 1.5rem;
  }
}

/* Footer
-------------------------------------------------- */
footer {
  margin-top: 5rem;
}

footer .widgets {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  padding: 3rem 0;
}

footer .copy {
  padding: 2rem 0;
  font-size: 0.75rem;
}

footer .menu-header {
  font-size: 0.8rem;
  color: $dark;
  text-transform: uppercase;
  letter-spacing: 0.03rem;
}

footer .menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

footer .menu-list a {
  color: $light-gray;
  font-size: 0.8rem;
}
footer .menu-list a:hover {
  color: $dark;
  text-decoration: none;
}

/* FORMS */
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder {
  color: $light-gray;
  font-size: 12px;
}

/* FLOATING LABELS
-------------------------------------------------- */
.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: $light-gray;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: $light-gray;
}

/*Fallback for Edge*/

@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder {
    color: $light-gray;
  }
}

/* Fallback for IE*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: $light-gray;
  }
}

/* FLOATING LABELS - INPUT GROUPS
-------------------------------------------------- */
.input-label-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-label-group > .form-control,
.input-label-group > .form-control-plaintext,
.input-label-group > .custom-select,
.input-label-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-label-group > input,
.input-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}

.input-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: $light-gray;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.input-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.input-label-group input:-ms-input-placeholder {
  color: transparent;
}

.input-label-group input::-ms-input-placeholder {
  color: transparent;
}

.input-label-group input::-moz-placeholder {
  color: transparent;
}

.input-label-group input::placeholder {
  color: transparent;
}

.input-label-group input:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.input-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: $light-gray;
}

/*Fallback for Edge*/

@supports (-ms-ime-align: auto) {
  .input-label-group > label {
    display: none;
  }
  .input-label-group input::-ms-input-placeholder {
    color: $light-gray;
  }
}

/* Fallback for IE*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .input-label-group > label {
    display: none;
  }
  .input-label-group input:-ms-input-placeholder {
    color: $light-gray;
  }
}

/* Show password overlay
-------------------------------------------------- */
.show-password {
  position: absolute;
  right: 0.5rem;
  font-size: 1.25rem;
  top: 10px;
}

/* Custom error messaging
-------------------------------------------------- */

#form-errors,
.form-errors {
  display: none;
}

.form-errors__show {
  display: block !important;
}

.field-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $danger;
}
.field-has-error {
  color: $danger !important;
}
.field-has-error .form-check-input {
  border-color: $danger;
}

/* Checkboxes & Radio Buttons
-------------------------------------------------------*/

.form-check {
  padding-left: 0;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  width: 20px;
  height: 20px;
  background-color: #f7f7f7;
  content: "";
  display: inline-block;
  font-size: 13px;
  margin: -4px 12px 0 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #ebebeb;
}

input[type="checkbox"]:checked + label:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  color: $dark;
  font-size: 10px;
  line-height: 18px;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  cursor: pointer;
  margin-bottom: 0;
  text-transform: none;
  letter-spacing: normal;
  color: $light-gray;
  font-size: 14px;
}

.radio-buttons > li,
.checkboxes > li {
  padding: 7px 0;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label:before {
  display: inline-block;
  content: "";
  width: 18px;
  height: 18px;
  background-color: #ededed;
  border-radius: 40px;
  margin: -3px 10px 0 0;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 0;
}

input[type="radio"]:checked + label:before {
  background-color: $dark;
  border: 5px solid #ededed;
  padding: 1px;
}

input[type="radio"]:focus {
  outline: none;
}

/* BTN-BLOCK SCREEN BREAKS
-------------------------------------------------- */
@media all and (min-width: 992px) and (max-width: 1199px) {
  .btn-lg-block {
    width: 100% !important;
    display: block !important;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .btn-md-block {
    width: 100% !important;
    display: block !important;
  }
}

@media all and (min-width: 576px) and (max-width: 767px) {
  .btn-sm-block {
    width: 100% !important;
    display: block !important;
  }
}

@media all and (max-width: 575px) {
  .btn-xs-block {
    width: 100% !important;
    display: block !important;
  }
}
