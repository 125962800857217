.square {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25em;
  text-align: center;
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.dimmed:after {
  content: " ";
  z-index: 5;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.5em;
}
/*

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
*/
