.title {
  font-size: 1.25rem;
  line-height: 1.2;
}

@media (max-width: 991.98px) {
  .title {
    font-size: 2rem;
  }
  .price {
    font-size: 1.5rem;
  }
}
